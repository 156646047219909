import React from "react"
import { Router } from "@reach/router"

import Home from "./home"
// import Construction from "./construction"
// import MEP from "./mep"
// import Environment from "./environment"

const Sectors = () => (
  <Router basepath="/sectors">
    <Home path="/" />
    {/* <Construction path="/construction" />
    <MEP path="/mep" />
    <Environment path="/environment" /> */}
  </Router>
)

export default Sectors
